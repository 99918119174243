/* tslint:disable */
/* eslint-disable */
/**
 * SciCrunch Antibody Registry API
 * Antibody Registry API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * The common fields between all REST operations for the antibody resource.
 * @export
 * @interface AbstractAntibody
 */
export interface AbstractAntibody {
    /**
     * Can include the following options: Unknown, Cocktail, Control, Isotype Control, Monoclonal, Monoclonal Secondary, Polyclonal, Polyclonal Secondary, Oligoclonal, Recombinant, Recombinant Monoclonal, Recombinant Monoclonal Secondary, Recombinant Polyclonal, Recombinant Polyclonal Secondary
     * @type {string}
     * @memberof AbstractAntibody
     */
    'clonality'?: AbstractAntibodyClonalityEnum;
    /**
     * The AA sequence that the antibody reagent binds to
     * @type {string}
     * @memberof AbstractAntibody
     */
    'epitope'?: string;
    /**
     * A free text comment.
     * @type {string}
     * @memberof AbstractAntibody
     */
    'comments'?: string;
    /**
     * Link to more information about the antibody. For personal antibodies this usually lists the the principal investigator\'s lab website or university affiliation.
     * @type {string}
     * @memberof AbstractAntibody
     */
    'url'?: string;
    /**
     * Name provided by the company or the investigator; this does not need to be unique.
     * @type {string}
     * @memberof AbstractAntibody
     */
    'abName'?: string;
    /**
     * The symbol of the antigen molecule that the antibody was raised against.
     * @type {string}
     * @memberof AbstractAntibody
     */
    'abTarget'?: string;
    /**
     * The identifier given by the manufacturer or creator of monoclonal antibodies, typically associated with the cell line name.
     * @type {string}
     * @memberof AbstractAntibody
     */
    'cloneId'?: string;
    /**
     * Can include the following: commercial, non-profit, personal, other
     * @type {string}
     * @memberof AbstractAntibody
     */
    'commercialType'?: AbstractAntibodyCommercialTypeEnum;
    /**
     * The manuscript that describes the creation of the antibody. 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'definingCitation'?: string;
    /**
     * The molecule that the antibody is conjugated to. This is generally used for secondary antibodies but the field is not restricted as there can be various tags on primary antibodies as well.  
     * @type {string}
     * @memberof AbstractAntibody
     */
    'productConjugate'?: string;
    /**
     * The formulation of the antibody product. Can include: Lyophilized, Affinity purified, Liquid
     * @type {string}
     * @memberof AbstractAntibody
     */
    'productForm'?: string;
    /**
     * Can include the following: IgG, IgY, IgA, IgM as well as the IgG subtypes
     * @type {string}
     * @memberof AbstractAntibody
     */
    'productIsotype'?: string;
    /**
     * The organism that the antibody was raised in; common antibodies are raised in goat, rabbit or mouse. Synthetic or bacterial origins can be noted for recombinant antibodies. 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'sourceOrganism'?: string;
    /**
     * The species associated with the antigen molecule. Multiple species are specified as a comma separated string
     * @type {Array<string>}
     * @memberof AbstractAntibody
     */
    'targetSpecies'?: Array<string>;
    /**
     * Protein identifier from UNIPROT 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'uniprotId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbstractAntibody
     */
    'applications'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'kitContents'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'abTargetEntrezId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAntibody
     */
    'abTargetUniprotId'?: string;
    /**
     * Number of citation for an Antibody - kept track using cronjob from scicrunch.
     * @type {number}
     * @memberof AbstractAntibody
     */
    'numOfCitation'?: number;
}

export const AbstractAntibodyClonalityEnum = {
    Unknown: 'unknown',
    Cocktail: 'cocktail',
    Control: 'control',
    IsotypeControl: 'isotype control',
    Monoclonal: 'monoclonal',
    MonoclonalSecondary: 'monoclonal secondary',
    Polyclonal: 'polyclonal',
    PolyclonalSecondary: 'polyclonal secondary',
    Oligoclonal: 'oligoclonal',
    Recombinant: 'recombinant',
    RecombinantMonoclonal: 'recombinant monoclonal',
    RecombinantMonoclonalSecondary: 'recombinant monoclonal secondary',
    RecombinantPolyclonal: 'recombinant polyclonal',
    RecombinantPolyclonalSecondary: 'recombinant polyclonal secondary'
} as const;

export type AbstractAntibodyClonalityEnum = typeof AbstractAntibodyClonalityEnum[keyof typeof AbstractAntibodyClonalityEnum];
export const AbstractAntibodyCommercialTypeEnum = {
    Commercial: 'commercial',
    NonProfit: 'non-profit',
    Personal: 'personal',
    Other: 'other'
} as const;

export type AbstractAntibodyCommercialTypeEnum = typeof AbstractAntibodyCommercialTypeEnum[keyof typeof AbstractAntibodyCommercialTypeEnum];

/**
 * The data type associated with the POST and PUT methods of the antibody resource
 * @export
 * @interface AddAntibody
 */
export interface AddAntibody {
    /**
     * Link to more information about the antibody. For personal antibodies this usually lists the the principal investigator\'s lab website or university affiliation.
     * @type {string}
     * @memberof AddAntibody
     */
    'url': string;
    /**
     * Can include the following options: Unknown, Cocktail, Control, Isotype Control, Monoclonal, Monoclonal Secondary, Polyclonal, Polyclonal Secondary, Oligoclonal, Recombinant, Recombinant Monoclonal, Recombinant Monoclonal Secondary, Recombinant Polyclonal, Recombinant Polyclonal Secondary
     * @type {string}
     * @memberof AddAntibody
     */
    'clonality'?: AddAntibodyClonalityEnum;
    /**
     * The AA sequence that the antibody reagent binds to
     * @type {string}
     * @memberof AddAntibody
     */
    'epitope'?: string;
    /**
     * A free text comment.
     * @type {string}
     * @memberof AddAntibody
     */
    'comments'?: string;
    /**
     * Name provided by the company or the investigator; this does not need to be unique.
     * @type {string}
     * @memberof AddAntibody
     */
    'abName'?: string;
    /**
     * The symbol of the antigen molecule that the antibody was raised against.
     * @type {string}
     * @memberof AddAntibody
     */
    'abTarget'?: string;
    /**
     * The identifier given by the manufacturer or creator of monoclonal antibodies, typically associated with the cell line name.
     * @type {string}
     * @memberof AddAntibody
     */
    'cloneId'?: string;
    /**
     * Can include the following: commercial, non-profit, personal, other
     * @type {string}
     * @memberof AddAntibody
     */
    'commercialType'?: AddAntibodyCommercialTypeEnum;
    /**
     * The manuscript that describes the creation of the antibody. 
     * @type {string}
     * @memberof AddAntibody
     */
    'definingCitation'?: string;
    /**
     * The molecule that the antibody is conjugated to. This is generally used for secondary antibodies but the field is not restricted as there can be various tags on primary antibodies as well.  
     * @type {string}
     * @memberof AddAntibody
     */
    'productConjugate'?: string;
    /**
     * The formulation of the antibody product. Can include: Lyophilized, Affinity purified, Liquid
     * @type {string}
     * @memberof AddAntibody
     */
    'productForm'?: string;
    /**
     * Can include the following: IgG, IgY, IgA, IgM as well as the IgG subtypes
     * @type {string}
     * @memberof AddAntibody
     */
    'productIsotype'?: string;
    /**
     * The organism that the antibody was raised in; common antibodies are raised in goat, rabbit or mouse. Synthetic or bacterial origins can be noted for recombinant antibodies. 
     * @type {string}
     * @memberof AddAntibody
     */
    'sourceOrganism'?: string;
    /**
     * The species associated with the antigen molecule. Multiple species are specified as a comma separated string
     * @type {Array<string>}
     * @memberof AddAntibody
     */
    'targetSpecies'?: Array<string>;
    /**
     * Protein identifier from UNIPROT 
     * @type {string}
     * @memberof AddAntibody
     */
    'uniprotId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddAntibody
     */
    'applications'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddAntibody
     */
    'kitContents'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAntibody
     */
    'abTargetEntrezId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAntibody
     */
    'abTargetUniprotId'?: string;
    /**
     * Number of citation for an Antibody - kept track using cronjob from scicrunch.
     * @type {number}
     * @memberof AddAntibody
     */
    'numOfCitation'?: number;
    /**
     * For company antibodies, the catalog number of the antibody. For personal/other antibodies, an identifier unique to the antibody.
     * @type {string}
     * @memberof AddAntibody
     */
    'catalogNum'?: string;
    /**
     * The name of the company or laboratory for commercial antibodies. The principal investigator name for personal/other antibodies.
     * @type {string}
     * @memberof AddAntibody
     */
    'vendorName'?: string;
}

export const AddAntibodyClonalityEnum = {
    Unknown: 'unknown',
    Cocktail: 'cocktail',
    Control: 'control',
    IsotypeControl: 'isotype control',
    Monoclonal: 'monoclonal',
    MonoclonalSecondary: 'monoclonal secondary',
    Polyclonal: 'polyclonal',
    PolyclonalSecondary: 'polyclonal secondary',
    Oligoclonal: 'oligoclonal',
    Recombinant: 'recombinant',
    RecombinantMonoclonal: 'recombinant monoclonal',
    RecombinantMonoclonalSecondary: 'recombinant monoclonal secondary',
    RecombinantPolyclonal: 'recombinant polyclonal',
    RecombinantPolyclonalSecondary: 'recombinant polyclonal secondary'
} as const;

export type AddAntibodyClonalityEnum = typeof AddAntibodyClonalityEnum[keyof typeof AddAntibodyClonalityEnum];
export const AddAntibodyCommercialTypeEnum = {
    Commercial: 'commercial',
    NonProfit: 'non-profit',
    Personal: 'personal',
    Other: 'other'
} as const;

export type AddAntibodyCommercialTypeEnum = typeof AddAntibodyCommercialTypeEnum[keyof typeof AddAntibodyCommercialTypeEnum];

/**
 * 
 * @export
 * @interface AddAntibodyAllOf
 */
export interface AddAntibodyAllOf {
    /**
     * 
     * @type {string}
     * @memberof AddAntibodyAllOf
     */
    'url': string;
}
/**
 * The data type associated with the antibody resource
 * @export
 * @interface Antibody
 */
export interface Antibody {
    /**
     * Thus value is the same as the Antibody identifier for newly added antibodies, different if antibody records have been consolidated or are not unique. 
     * @type {string}
     * @memberof Antibody
     */
    'accession'?: string;
    /**
     * 
     * @type {AntibodyStatusEnum}
     * @memberof Antibody
     */
    'status'?: AntibodyStatusEnum;
    /**
     * Feedback to the submitted stored here
     * @type {string}
     * @memberof Antibody
     */
    'feedback'?: string;
    /**
     * Antibody identifier
     * @type {string}
     * @memberof Antibody
     */
    'abId'?: string;
    /**
     * The alternative catalog numbers for this product, delimited by comma, e.g., 9101S, 9101P, 9191L
     * @type {string}
     * @memberof Antibody
     */
    'catAlt'?: string;
    /**
     * Unix time stamp when the row was last updated
     * @type {string}
     * @memberof Antibody
     */
    'curateTime'?: string;
    /**
     * Curator comment about this reagent 
     * @type {string}
     * @memberof Antibody
     */
    'curatorComment'?: string;
    /**
     * The date on which the antibody product was found to be discontinued
     * @type {string}
     * @memberof Antibody
     */
    'discDate'?: string;
    /**
     * Unix time stamp when the row was inserted.
     * @type {string}
     * @memberof Antibody
     */
    'insertTime'?: string;
    /**
     * Any modification to the target protein
     * @type {string}
     * @memberof Antibody
     */
    'targetModification'?: string;
    /**
     * The subregion of the target protein that the epitope is contained in
     * @type {string}
     * @memberof Antibody
     */
    'targetSubregion'?: string;
    /**
     * 
     * @type {number}
     * @memberof Antibody
     */
    'vendorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Antibody
     */
    'lastEditTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof Antibody
     */
    'ix'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Antibody
     */
    'showLink'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Antibody
     */
    'vendorUrl'?: Array<string>;
    /**
     * Can include the following options: Unknown, Cocktail, Control, Isotype Control, Monoclonal, Monoclonal Secondary, Polyclonal, Polyclonal Secondary, Oligoclonal, Recombinant, Recombinant Monoclonal, Recombinant Monoclonal Secondary, Recombinant Polyclonal, Recombinant Polyclonal Secondary
     * @type {string}
     * @memberof Antibody
     */
    'clonality'?: AntibodyClonalityEnum;
    /**
     * The AA sequence that the antibody reagent binds to
     * @type {string}
     * @memberof Antibody
     */
    'epitope'?: string;
    /**
     * A free text comment.
     * @type {string}
     * @memberof Antibody
     */
    'comments'?: string;
    /**
     * Link to more information about the antibody. For personal antibodies this usually lists the the principal investigator\'s lab website or university affiliation.
     * @type {string}
     * @memberof Antibody
     */
    'url'?: string;
    /**
     * Name provided by the company or the investigator; this does not need to be unique.
     * @type {string}
     * @memberof Antibody
     */
    'abName'?: string;
    /**
     * The symbol of the antigen molecule that the antibody was raised against.
     * @type {string}
     * @memberof Antibody
     */
    'abTarget'?: string;
    /**
     * The identifier given by the manufacturer or creator of monoclonal antibodies, typically associated with the cell line name.
     * @type {string}
     * @memberof Antibody
     */
    'cloneId'?: string;
    /**
     * Can include the following: commercial, non-profit, personal, other
     * @type {string}
     * @memberof Antibody
     */
    'commercialType'?: AntibodyCommercialTypeEnum;
    /**
     * The manuscript that describes the creation of the antibody. 
     * @type {string}
     * @memberof Antibody
     */
    'definingCitation'?: string;
    /**
     * The molecule that the antibody is conjugated to. This is generally used for secondary antibodies but the field is not restricted as there can be various tags on primary antibodies as well.  
     * @type {string}
     * @memberof Antibody
     */
    'productConjugate'?: string;
    /**
     * The formulation of the antibody product. Can include: Lyophilized, Affinity purified, Liquid
     * @type {string}
     * @memberof Antibody
     */
    'productForm'?: string;
    /**
     * Can include the following: IgG, IgY, IgA, IgM as well as the IgG subtypes
     * @type {string}
     * @memberof Antibody
     */
    'productIsotype'?: string;
    /**
     * The organism that the antibody was raised in; common antibodies are raised in goat, rabbit or mouse. Synthetic or bacterial origins can be noted for recombinant antibodies. 
     * @type {string}
     * @memberof Antibody
     */
    'sourceOrganism'?: string;
    /**
     * The species associated with the antigen molecule. Multiple species are specified as a comma separated string
     * @type {Array<string>}
     * @memberof Antibody
     */
    'targetSpecies'?: Array<string>;
    /**
     * Protein identifier from UNIPROT 
     * @type {string}
     * @memberof Antibody
     */
    'uniprotId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Antibody
     */
    'applications'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Antibody
     */
    'kitContents'?: string;
    /**
     * 
     * @type {string}
     * @memberof Antibody
     */
    'abTargetEntrezId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Antibody
     */
    'abTargetUniprotId'?: string;
    /**
     * Number of citation for an Antibody - kept track using cronjob from scicrunch.
     * @type {number}
     * @memberof Antibody
     */
    'numOfCitation'?: number;
    /**
     * For company antibodies, the catalog number of the antibody. For personal/other antibodies, an identifier unique to the antibody.
     * @type {string}
     * @memberof Antibody
     */
    'catalogNum'?: string;
    /**
     * The name of the company or laboratory for commercial antibodies. The principal investigator name for personal/other antibodies.
     * @type {string}
     * @memberof Antibody
     */
    'vendorName'?: string;
}

export const AntibodyClonalityEnum = {
    Unknown: 'unknown',
    Cocktail: 'cocktail',
    Control: 'control',
    IsotypeControl: 'isotype control',
    Monoclonal: 'monoclonal',
    MonoclonalSecondary: 'monoclonal secondary',
    Polyclonal: 'polyclonal',
    PolyclonalSecondary: 'polyclonal secondary',
    Oligoclonal: 'oligoclonal',
    Recombinant: 'recombinant',
    RecombinantMonoclonal: 'recombinant monoclonal',
    RecombinantMonoclonalSecondary: 'recombinant monoclonal secondary',
    RecombinantPolyclonal: 'recombinant polyclonal',
    RecombinantPolyclonalSecondary: 'recombinant polyclonal secondary'
} as const;

export type AntibodyClonalityEnum = typeof AntibodyClonalityEnum[keyof typeof AntibodyClonalityEnum];
export const AntibodyCommercialTypeEnum = {
    Commercial: 'commercial',
    NonProfit: 'non-profit',
    Personal: 'personal',
    Other: 'other'
} as const;

export type AntibodyCommercialTypeEnum = typeof AntibodyCommercialTypeEnum[keyof typeof AntibodyCommercialTypeEnum];

/**
 * 
 * @export
 * @interface AntibodyAllOf
 */
export interface AntibodyAllOf {
    /**
     * Thus value is the same as the Antibody identifier for newly added antibodies, different if antibody records have been consolidated or are not unique. 
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'accession'?: string;
    /**
     * 
     * @type {AntibodyStatusEnum}
     * @memberof AntibodyAllOf
     */
    'status'?: AntibodyStatusEnum;
    /**
     * Feedback to the submitted stored here
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'feedback'?: string;
    /**
     * Antibody identifier
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'abId'?: string;
    /**
     * The alternative catalog numbers for this product, delimited by comma, e.g., 9101S, 9101P, 9191L
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'catAlt'?: string;
    /**
     * Unix time stamp when the row was last updated
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'curateTime'?: string;
    /**
     * Curator comment about this reagent 
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'curatorComment'?: string;
    /**
     * The date on which the antibody product was found to be discontinued
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'discDate'?: string;
    /**
     * Unix time stamp when the row was inserted.
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'insertTime'?: string;
    /**
     * Any modification to the target protein
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'targetModification'?: string;
    /**
     * The subregion of the target protein that the epitope is contained in
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'targetSubregion'?: string;
    /**
     * 
     * @type {number}
     * @memberof AntibodyAllOf
     */
    'vendorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AntibodyAllOf
     */
    'lastEditTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof AntibodyAllOf
     */
    'ix'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AntibodyAllOf
     */
    'showLink'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AntibodyAllOf
     */
    'vendorUrl'?: Array<string>;
}
/**
 * Related attributes used to uniquely identify antibodies
 * @export
 * @interface AntibodyCoreId
 */
export interface AntibodyCoreId {
    /**
     * For company antibodies, the catalog number of the antibody. For personal/other antibodies, an identifier unique to the antibody.
     * @type {string}
     * @memberof AntibodyCoreId
     */
    'catalogNum'?: string;
    /**
     * The name of the company or laboratory for commercial antibodies. The principal investigator name for personal/other antibodies.
     * @type {string}
     * @memberof AntibodyCoreId
     */
    'vendorName'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AntibodyStatusEnum = {
    Curated: 'CURATED',
    Rejected: 'REJECTED',
    Queue: 'QUEUE',
    UnderReview: 'UNDER_REVIEW'
} as const;

export type AntibodyStatusEnum = typeof AntibodyStatusEnum[keyof typeof AntibodyStatusEnum];


/**
 * Information about the data in the system
 * @export
 * @interface DataInfo
 */
export interface DataInfo {
    /**
     * The total number of antibodies in the system
     * @type {number}
     * @memberof DataInfo
     */
    'total': number;
    /**
     * 
     * @type {string}
     * @memberof DataInfo
     */
    'lastupdate': string;
}
/**
 * The search request body that allows filtering combinations over multiple columns
 * @export
 * @interface FilterRequest
 */
export interface FilterRequest {
    /**
     * Array of key-value pairs, where key represents the column and value the string that should be contained
     * @type {Array<KeyValuePair>}
     * @memberof FilterRequest
     */
    'contains'?: Array<KeyValuePair>;
    /**
     * Array of key-value pairs, where key represents the column and value the string that should be equalled to
     * @type {Array<KeyValuePair>}
     * @memberof FilterRequest
     */
    'equals'?: Array<KeyValuePair>;
    /**
     * Represents the page requested, considering the size parameter
     * @type {number}
     * @memberof FilterRequest
     */
    'page'?: number;
    /**
     * Corresponds to the cardinality of antibodies requested
     * @type {number}
     * @memberof FilterRequest
     */
    'size'?: number;
    /**
     * The string to use to search for Antibodies
     * @type {string}
     * @memberof FilterRequest
     */
    'search'?: string;
    /**
     * Array of key-value pairs, where key represents the column and value the string that should be ending with
     * @type {Array<KeyValuePair>}
     * @memberof FilterRequest
     */
    'endsWith'?: Array<KeyValuePair>;
    /**
     * Array of key-value pairs, where key represents the column and value the string ascending or descending  Order in the array, matches with the order of sorting filters, index 0 will be used to sort first
     * @type {Array<KeyValueSortOrderPair>}
     * @memberof FilterRequest
     */
    'sortOn'?: Array<KeyValueSortOrderPair>;
    /**
     * Array of key-value pairs, where key represents the column and value the string that should be starting with
     * @type {Array<KeyValuePair>}
     * @memberof FilterRequest
     */
    'startsWith'?: Array<KeyValuePair>;
    /**
     * Array of strings, where string represents the column which should be empty.
     * @type {Array<string>}
     * @memberof FilterRequest
     */
    'isEmpty'?: Array<string>;
    /**
     * Array of strings, where string represents the column which should not be empty.
     * @type {Array<string>}
     * @memberof FilterRequest
     */
    'isNotEmpty'?: Array<string>;
    /**
     * Array of strings, where string represents the column which should not be empty.
     * @type {Array<KeyValueArrayPair>}
     * @memberof FilterRequest
     */
    'isAnyOf'?: Array<KeyValueArrayPair>;
    /**
     * Which operation to perform in the Backend - AND or OR.
     * @type {string}
     * @memberof FilterRequest
     */
    'operation'?: FilterRequestOperationEnum;
    /**
     * Whether to get users antibodies or not.
     * @type {boolean}
     * @memberof FilterRequest
     */
    'isUserScope'?: boolean;
}

export const FilterRequestOperationEnum = {
    And: 'and',
    Or: 'or'
} as const;

export type FilterRequestOperationEnum = typeof FilterRequestOperationEnum[keyof typeof FilterRequestOperationEnum];

/**
 * 
 * @export
 * @interface IngestRequest
 */
export interface IngestRequest {
    /**
     * 
     * @type {string}
     * @memberof IngestRequest
     */
    'driveLinkOrId'?: string;
    /**
     * Whether to add directly to the current data without reset
     * @type {boolean}
     * @memberof IngestRequest
     */
    'hot'?: boolean;
}
/**
 * Utility type to represent a key-value object, where value is an array. This is helpful when the filter is - isAnyOf, where filter column needs to be matched with multiple strings.
 * @export
 * @interface KeyValueArrayPair
 */
export interface KeyValueArrayPair {
    /**
     * String representation of the key
     * @type {string}
     * @memberof KeyValueArrayPair
     */
    'key': string;
    /**
     * String representation of the value
     * @type {Array<string>}
     * @memberof KeyValueArrayPair
     */
    'value': Array<string>;
}
/**
 * Utility type to represent a key-value object
 * @export
 * @interface KeyValuePair
 */
export interface KeyValuePair {
    /**
     * String representation of the key
     * @type {string}
     * @memberof KeyValuePair
     */
    'key': string;
    /**
     * String representation of the value
     * @type {string}
     * @memberof KeyValuePair
     */
    'value': string;
}
/**
 * Utility type to represent a key-value object, where value is enum - \"asc\" or \"desc\" to describe if the sorting order is ascending or descending. 
 * @export
 * @interface KeyValueSortOrderPair
 */
export interface KeyValueSortOrderPair {
    /**
     * String representation of the key
     * @type {string}
     * @memberof KeyValueSortOrderPair
     */
    'key': string;
    /**
     * String representation of the value
     * @type {string}
     * @memberof KeyValueSortOrderPair
     */
    'sortorder'?: KeyValueSortOrderPairSortorderEnum;
}

export const KeyValueSortOrderPairSortorderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KeyValueSortOrderPairSortorderEnum = typeof KeyValueSortOrderPairSortorderEnum[keyof typeof KeyValueSortOrderPairSortorderEnum];

/**
 * 
 * @export
 * @interface PaginatedAntibodies
 */
export interface PaginatedAntibodies {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAntibodies
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAntibodies
     */
    'totalElements': number;
    /**
     * 
     * @type {Array<Antibody>}
     * @memberof PaginatedAntibodies
     */
    'items': Array<Antibody>;
}
/**
 * Response of the list of partners and related images
 * @export
 * @interface PartnerResponseObject
 */
export interface PartnerResponseObject {
    /**
     * 
     * @type {string}
     * @memberof PartnerResponseObject
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponseObject
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponseObject
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchCriteriaOptions = {
    Contains: 'contains',
    Equals: 'equals',
    EndsWith: 'endsWith',
    StartsWith: 'startsWith',
    SortOn: 'sortOn',
    IsEmpty: 'isEmpty',
    IsNotEmpty: 'isNotEmpty',
    IsAnyOf: 'isAnyOf',
    Operation: 'operation',
    IsUserScope: 'isUserScope',
    Page: 'page',
    Size: 'size',
    Search: 'search'
} as const;

export type SearchCriteriaOptions = typeof SearchCriteriaOptions[keyof typeof SearchCriteriaOptions];


/**
 * The data type associated with the POST and PUT methods of the antibody resource
 * @export
 * @interface UpdateAntibody
 */
export interface UpdateAntibody {
    /**
     * Link to more information about the antibody. For personal antibodies this usually lists the the principal investigator\'s lab website or university affiliation.
     * @type {string}
     * @memberof UpdateAntibody
     */
    'url': string;
    /**
     * Can include the following options: Unknown, Cocktail, Control, Isotype Control, Monoclonal, Monoclonal Secondary, Polyclonal, Polyclonal Secondary, Oligoclonal, Recombinant, Recombinant Monoclonal, Recombinant Monoclonal Secondary, Recombinant Polyclonal, Recombinant Polyclonal Secondary
     * @type {string}
     * @memberof UpdateAntibody
     */
    'clonality'?: UpdateAntibodyClonalityEnum;
    /**
     * The AA sequence that the antibody reagent binds to
     * @type {string}
     * @memberof UpdateAntibody
     */
    'epitope'?: string;
    /**
     * A free text comment.
     * @type {string}
     * @memberof UpdateAntibody
     */
    'comments'?: string;
    /**
     * Name provided by the company or the investigator; this does not need to be unique.
     * @type {string}
     * @memberof UpdateAntibody
     */
    'abName'?: string;
    /**
     * The symbol of the antigen molecule that the antibody was raised against.
     * @type {string}
     * @memberof UpdateAntibody
     */
    'abTarget'?: string;
    /**
     * The identifier given by the manufacturer or creator of monoclonal antibodies, typically associated with the cell line name.
     * @type {string}
     * @memberof UpdateAntibody
     */
    'cloneId'?: string;
    /**
     * Can include the following: commercial, non-profit, personal, other
     * @type {string}
     * @memberof UpdateAntibody
     */
    'commercialType'?: UpdateAntibodyCommercialTypeEnum;
    /**
     * The manuscript that describes the creation of the antibody. 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'definingCitation'?: string;
    /**
     * The molecule that the antibody is conjugated to. This is generally used for secondary antibodies but the field is not restricted as there can be various tags on primary antibodies as well.  
     * @type {string}
     * @memberof UpdateAntibody
     */
    'productConjugate'?: string;
    /**
     * The formulation of the antibody product. Can include: Lyophilized, Affinity purified, Liquid
     * @type {string}
     * @memberof UpdateAntibody
     */
    'productForm'?: string;
    /**
     * Can include the following: IgG, IgY, IgA, IgM as well as the IgG subtypes
     * @type {string}
     * @memberof UpdateAntibody
     */
    'productIsotype'?: string;
    /**
     * The organism that the antibody was raised in; common antibodies are raised in goat, rabbit or mouse. Synthetic or bacterial origins can be noted for recombinant antibodies. 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'sourceOrganism'?: string;
    /**
     * The species associated with the antigen molecule. Multiple species are specified as a comma separated string
     * @type {Array<string>}
     * @memberof UpdateAntibody
     */
    'targetSpecies'?: Array<string>;
    /**
     * Protein identifier from UNIPROT 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'uniprotId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAntibody
     */
    'applications'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'kitContents'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'abTargetEntrezId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAntibody
     */
    'abTargetUniprotId'?: string;
    /**
     * Number of citation for an Antibody - kept track using cronjob from scicrunch.
     * @type {number}
     * @memberof UpdateAntibody
     */
    'numOfCitation'?: number;
}

export const UpdateAntibodyClonalityEnum = {
    Unknown: 'unknown',
    Cocktail: 'cocktail',
    Control: 'control',
    IsotypeControl: 'isotype control',
    Monoclonal: 'monoclonal',
    MonoclonalSecondary: 'monoclonal secondary',
    Polyclonal: 'polyclonal',
    PolyclonalSecondary: 'polyclonal secondary',
    Oligoclonal: 'oligoclonal',
    Recombinant: 'recombinant',
    RecombinantMonoclonal: 'recombinant monoclonal',
    RecombinantMonoclonalSecondary: 'recombinant monoclonal secondary',
    RecombinantPolyclonal: 'recombinant polyclonal',
    RecombinantPolyclonalSecondary: 'recombinant polyclonal secondary'
} as const;

export type UpdateAntibodyClonalityEnum = typeof UpdateAntibodyClonalityEnum[keyof typeof UpdateAntibodyClonalityEnum];
export const UpdateAntibodyCommercialTypeEnum = {
    Commercial: 'commercial',
    NonProfit: 'non-profit',
    Personal: 'personal',
    Other: 'other'
} as const;

export type UpdateAntibodyCommercialTypeEnum = typeof UpdateAntibodyCommercialTypeEnum[keyof typeof UpdateAntibodyCommercialTypeEnum];


/**
 * AntibodyApi - axios parameter creator
 * @export
 */
export const AntibodyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AntibodyStatusEnum} [status] Requests for Antibodies for a specific status - by default it returns All Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antibodiesExportAdminGet: async (status?: AntibodyStatusEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antibodies/export/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antibodiesExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antibodies/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `Antibody`.
         * @summary Create a Antibody
         * @param {AddAntibody} addAntibody A new &#x60;Antibody&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAntibody: async (addAntibody: AddAntibody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addAntibody' is not null or undefined
            assertParamExists('createAntibody', 'addAntibody', addAntibody)
            const localVarPath = `/antibodies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAntibody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [updatedFrom] start date to include. ISO format
         * @param {string} [updatedTo] end update date to include. ISO format
         * @param {string} [status] Add a status to filter the query - CURATED, REJECTED, QUEUE, UNDER_REVIEW. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntibodies: async (page?: number, size?: number, updatedFrom?: string, updatedTo?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antibodies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (updatedFrom !== undefined) {
                localVarQueryParameter['updated_from'] = (updatedFrom as any instanceof Date) ?
                    (updatedFrom as any).toISOString() :
                    updatedFrom;
            }

            if (updatedTo !== undefined) {
                localVarQueryParameter['updated_to'] = (updatedTo as any instanceof Date) ?
                    (updatedTo as any).toISOString() :
                    updatedTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the details of a single instance of a `Antibody`.
         * @summary Get a Antibody
         * @param {number} antibodyId The unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntibody: async (antibodyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'antibodyId' is not null or undefined
            assertParamExists('getAntibody', 'antibodyId', antibodyId)
            const localVarPath = `/antibodies/{antibody_id}`
                .replace(`{${"antibody_id"}}`, encodeURIComponent(String(antibodyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get antibody by the accession number
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByAccession: async (accessionNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessionNumber' is not null or undefined
            assertParamExists('getByAccession', 'accessionNumber', accessionNumber)
            const localVarPath = `/antibodies/user/{accession_number}`
                .replace(`{${"accession_number"}}`, encodeURIComponent(String(accessionNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAntibodies: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antibodies/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a submitted `Antibody`.
         * @summary Update a submitted Antibody
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {UpdateAntibody} updateAntibody Updated &#x60;Antibody&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAntibody: async (accessionNumber: number, updateAntibody: UpdateAntibody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessionNumber' is not null or undefined
            assertParamExists('updateUserAntibody', 'accessionNumber', accessionNumber)
            // verify required parameter 'updateAntibody' is not null or undefined
            assertParamExists('updateUserAntibody', 'updateAntibody', updateAntibody)
            const localVarPath = `/antibodies/user/{accession_number}`
                .replace(`{${"accession_number"}}`, encodeURIComponent(String(accessionNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAntibody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AntibodyApi - functional programming interface
 * @export
 */
export const AntibodyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AntibodyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AntibodyStatusEnum} [status] Requests for Antibodies for a specific status - by default it returns All Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async antibodiesExportAdminGet(status?: AntibodyStatusEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.antibodiesExportAdminGet(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async antibodiesExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.antibodiesExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `Antibody`.
         * @summary Create a Antibody
         * @param {AddAntibody} addAntibody A new &#x60;Antibody&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAntibody(addAntibody: AddAntibody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Antibody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAntibody(addAntibody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [updatedFrom] start date to include. ISO format
         * @param {string} [updatedTo] end update date to include. ISO format
         * @param {string} [status] Add a status to filter the query - CURATED, REJECTED, QUEUE, UNDER_REVIEW. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAntibodies(page?: number, size?: number, updatedFrom?: string, updatedTo?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAntibodies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAntibodies(page, size, updatedFrom, updatedTo, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the details of a single instance of a `Antibody`.
         * @summary Get a Antibody
         * @param {number} antibodyId The unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAntibody(antibodyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Antibody>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAntibody(antibodyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get antibody by the accession number
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByAccession(accessionNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Antibody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByAccession(accessionNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAntibodies(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAntibodies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAntibodies(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a submitted `Antibody`.
         * @summary Update a submitted Antibody
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {UpdateAntibody} updateAntibody Updated &#x60;Antibody&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAntibody(accessionNumber: number, updateAntibody: UpdateAntibody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAntibody(accessionNumber, updateAntibody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AntibodyApi - factory interface
 * @export
 */
export const AntibodyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AntibodyApiFp(configuration)
    return {
        /**
         * 
         * @param {AntibodyStatusEnum} [status] Requests for Antibodies for a specific status - by default it returns All Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antibodiesExportAdminGet(status?: AntibodyStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.antibodiesExportAdminGet(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antibodiesExportGet(options?: any): AxiosPromise<void> {
            return localVarFp.antibodiesExportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `Antibody`.
         * @summary Create a Antibody
         * @param {AddAntibody} addAntibody A new &#x60;Antibody&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAntibody(addAntibody: AddAntibody, options?: any): AxiosPromise<Antibody> {
            return localVarFp.createAntibody(addAntibody, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [updatedFrom] start date to include. ISO format
         * @param {string} [updatedTo] end update date to include. ISO format
         * @param {string} [status] Add a status to filter the query - CURATED, REJECTED, QUEUE, UNDER_REVIEW. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntibodies(page?: number, size?: number, updatedFrom?: string, updatedTo?: string, status?: string, options?: any): AxiosPromise<PaginatedAntibodies> {
            return localVarFp.getAntibodies(page, size, updatedFrom, updatedTo, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the details of a single instance of a `Antibody`.
         * @summary Get a Antibody
         * @param {number} antibodyId The unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAntibody(antibodyId: number, options?: any): AxiosPromise<Array<Antibody>> {
            return localVarFp.getAntibody(antibodyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get antibody by the accession number
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByAccession(accessionNumber: number, options?: any): AxiosPromise<Antibody> {
            return localVarFp.getByAccession(accessionNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of `Antibody` entities.
         * @summary List Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAntibodies(page?: number, size?: number, options?: any): AxiosPromise<PaginatedAntibodies> {
            return localVarFp.getUserAntibodies(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a submitted `Antibody`.
         * @summary Update a submitted Antibody
         * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
         * @param {UpdateAntibody} updateAntibody Updated &#x60;Antibody&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAntibody(accessionNumber: number, updateAntibody: UpdateAntibody, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserAntibody(accessionNumber, updateAntibody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AntibodyApi - object-oriented interface
 * @export
 * @class AntibodyApi
 * @extends {BaseAPI}
 */
export class AntibodyApi extends BaseAPI {
    /**
     * 
     * @param {AntibodyStatusEnum} [status] Requests for Antibodies for a specific status - by default it returns All Antibodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public antibodiesExportAdminGet(status?: AntibodyStatusEnum, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).antibodiesExportAdminGet(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public antibodiesExportGet(options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).antibodiesExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `Antibody`.
     * @summary Create a Antibody
     * @param {AddAntibody} addAntibody A new &#x60;Antibody&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public createAntibody(addAntibody: AddAntibody, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).createAntibody(addAntibody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of `Antibody` entities.
     * @summary List Antibodies
     * @param {number} [page] Represents the page requested, considering the size parameter
     * @param {number} [size] Corresponds to the cardinality of antibodies requested
     * @param {string} [updatedFrom] start date to include. ISO format
     * @param {string} [updatedTo] end update date to include. ISO format
     * @param {string} [status] Add a status to filter the query - CURATED, REJECTED, QUEUE, UNDER_REVIEW. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public getAntibodies(page?: number, size?: number, updatedFrom?: string, updatedTo?: string, status?: string, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).getAntibodies(page, size, updatedFrom, updatedTo, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the details of a single instance of a `Antibody`.
     * @summary Get a Antibody
     * @param {number} antibodyId The unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public getAntibody(antibodyId: number, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).getAntibody(antibodyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get antibody by the accession number
     * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public getByAccession(accessionNumber: number, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).getByAccession(accessionNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of `Antibody` entities.
     * @summary List Antibodies
     * @param {number} [page] Represents the page requested, considering the size parameter
     * @param {number} [size] Corresponds to the cardinality of antibodies requested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public getUserAntibodies(page?: number, size?: number, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).getUserAntibodies(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a submitted `Antibody`.
     * @summary Update a submitted Antibody
     * @param {number} accessionNumber An unique identifier for a &#x60;Antibody&#x60; -- stripped from \&quot;AB_\&quot;
     * @param {UpdateAntibody} updateAntibody Updated &#x60;Antibody&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntibodyApi
     */
    public updateUserAntibody(accessionNumber: number, updateAntibody: UpdateAntibody, options?: AxiosRequestConfig) {
        return AntibodyApiFp(this.configuration).updateUserAntibody(accessionNumber, updateAntibody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datainfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datainfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the list of partners and related images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datainfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datainfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the list of partners and related images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerResponseObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datainfoGet(options?: any): AxiosPromise<DataInfo> {
            return localVarFp.datainfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the list of partners and related images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners(options?: any): AxiosPromise<Array<PartnerResponseObject>> {
            return localVarFp.getPartners(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public datainfoGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).datainfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the list of partners and related images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPartners(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPartners(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IngestApi - axios parameter creator
 * @export
 */
export const IngestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ingest antibody\'s csv data into the database
         * @summary Ingest antibody\'s csv data into the database
         * @param {IngestRequest} ingestRequest The google drive file id of the zipped data to ingest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingest: async (ingestRequest: IngestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingestRequest' is not null or undefined
            assertParamExists('ingest', 'ingestRequest', ingestRequest)
            const localVarPath = `/ingest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngestApi - functional programming interface
 * @export
 */
export const IngestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngestApiAxiosParamCreator(configuration)
    return {
        /**
         * Ingest antibody\'s csv data into the database
         * @summary Ingest antibody\'s csv data into the database
         * @param {IngestRequest} ingestRequest The google drive file id of the zipped data to ingest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingest(ingestRequest: IngestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingest(ingestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngestApi - factory interface
 * @export
 */
export const IngestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngestApiFp(configuration)
    return {
        /**
         * Ingest antibody\'s csv data into the database
         * @summary Ingest antibody\'s csv data into the database
         * @param {IngestRequest} ingestRequest The google drive file id of the zipped data to ingest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingest(ingestRequest: IngestRequest, options?: any): AxiosPromise<void> {
            return localVarFp.ingest(ingestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngestApi - object-oriented interface
 * @export
 * @class IngestApi
 * @extends {BaseAPI}
 */
export class IngestApi extends BaseAPI {
    /**
     * Ingest antibody\'s csv data into the database
     * @summary Ingest antibody\'s csv data into the database
     * @param {IngestRequest} ingestRequest The google drive file id of the zipped data to ingest.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestApi
     */
    public ingest(ingestRequest: IngestRequest, options?: AxiosRequestConfig) {
        return IngestApiFp(this.configuration).ingest(ingestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets a list of `Antibody` entities related with the body parameters
         * @summary Search on Antibodies with custom filters
         * @param {FilterRequest} filterRequest A new &#x60;FilterRequest&#x60; to be used on the antibodies search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterAntibodies: async (filterRequest: FilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterRequest' is not null or undefined
            assertParamExists('filterAntibodies', 'filterRequest', filterRequest)
            const localVarPath = `/antibodies/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of `Antibody` entities related with the search query
         * @summary Full text search on Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [search] The string to use for full text search on Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftsAntibodies: async (page?: number, size?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/antibodies/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets a list of `Antibody` entities related with the body parameters
         * @summary Search on Antibodies with custom filters
         * @param {FilterRequest} filterRequest A new &#x60;FilterRequest&#x60; to be used on the antibodies search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterAntibodies(filterRequest: FilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAntibodies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterAntibodies(filterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of `Antibody` entities related with the search query
         * @summary Full text search on Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [search] The string to use for full text search on Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ftsAntibodies(page?: number, size?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAntibodies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ftsAntibodies(page, size, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * Gets a list of `Antibody` entities related with the body parameters
         * @summary Search on Antibodies with custom filters
         * @param {FilterRequest} filterRequest A new &#x60;FilterRequest&#x60; to be used on the antibodies search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterAntibodies(filterRequest: FilterRequest, options?: any): AxiosPromise<PaginatedAntibodies> {
            return localVarFp.filterAntibodies(filterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of `Antibody` entities related with the search query
         * @summary Full text search on Antibodies
         * @param {number} [page] Represents the page requested, considering the size parameter
         * @param {number} [size] Corresponds to the cardinality of antibodies requested
         * @param {string} [search] The string to use for full text search on Antibodies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftsAntibodies(page?: number, size?: number, search?: string, options?: any): AxiosPromise<PaginatedAntibodies> {
            return localVarFp.ftsAntibodies(page, size, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * Gets a list of `Antibody` entities related with the body parameters
     * @summary Search on Antibodies with custom filters
     * @param {FilterRequest} filterRequest A new &#x60;FilterRequest&#x60; to be used on the antibodies search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public filterAntibodies(filterRequest: FilterRequest, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).filterAntibodies(filterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of `Antibody` entities related with the search query
     * @summary Full text search on Antibodies
     * @param {number} [page] Represents the page requested, considering the size parameter
     * @param {number} [size] Corresponds to the cardinality of antibodies requested
     * @param {string} [search] The string to use for full text search on Antibodies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public ftsAntibodies(page?: number, size?: number, search?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).ftsAntibodies(page, size, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Checks if application is healthy
         * @summary Checks if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        live: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/live`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if application is up
         * @summary Checks if application is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if application is ready to take requests
         * @summary Checks if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * Checks if application is healthy
         * @summary Checks if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async live(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.live(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks if application is up
         * @summary Checks if application is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks if application is ready to take requests
         * @summary Checks if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ready(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ready(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * Checks if application is healthy
         * @summary Checks if application is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        live(options?: any): AxiosPromise<string> {
            return localVarFp.live(options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if application is up
         * @summary Checks if application is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<string> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if application is ready to take requests
         * @summary Checks if application is ready to take requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ready(options?: any): AxiosPromise<string> {
            return localVarFp.ready(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * Checks if application is healthy
     * @summary Checks if application is healthy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public live(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).live(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if application is up
     * @summary Checks if application is up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public ping(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if application is ready to take requests
     * @summary Checks if application is ready to take requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public ready(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).ready(options).then((request) => request(this.axios, this.basePath));
    }
}


